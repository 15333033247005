.qr-code{
    width: 100% !important;
    height: auto !important;
    border: none;
}
.close-btn-qr{
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
}
.ant-modal-header{
  border-bottom: 1px solid #dee2e6;
  padding: 1rem 1rem;
}
.ant-modal-body{
  padding: 1rem;
}
.ant-modal-footer{
  border-top: 1px solid #dee2e6;
  padding: 1rem;
}
.ant-card-body{
  padding: 0 !important;
}
.ant-modal-content{
  padding: 0 !important;
}
.popup-card{
    border: 0;
    border-radius: 0;
  }
  .qr-btn-style{
    background-color: transparent;
    color: #343a40;
    border-color: #343a40;
  }
  .qr-btn-style:hover{
    background-color: #343a40 !important;
  }
  .button-share-qr{
    display: inline-block;
    padding: 0.2em 0.5em;
    border-radius: 0.25em;
    cursor: pointer;
    color: #343a40;
    border: 1px solid #343a40;
    background-color: transparent;
  }
  .button-share-qr button{
    background-color: transparent;
    border-color: transparent;
  }