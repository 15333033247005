.copyLinks{
    margin-bottom: 1rem;
}
.copylinks-input{
    background-color: #e9ecef !important;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057 !important;
}
.copylinks-labels{
    margin-bottom: 0.5rem;
    display: inline-block;
}