.header-link{
    color: #343a40;
    text-decoration: none;
}
.header-link:hover{
    text-decoration: underline;
}
.header{
    display: grid;
    justify-content: end;
    margin-bottom: 20px;
    margin-top: 40px;
    padding: 0.5rem;
    text-align: right;
}
.paddingRemoved{
    margin: 0;
    line-height: 1.2;
    font-weight: 500;
}
.heading1-size{
    font-size: 2.5rem;
}
.heading2-size{
    font-size: 1rem;
}