  .container {
    /* width: 80%; */
    max-width: 1200px;
    /* margin: 0 auto; */
  }

  .container * {
    box-sizing: border-box;
  }

  .flexx {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 1rem;
  }

  .label label {
    /* flex: 1 0 120px;
    max-width: 220px;
    text-align: right;
    padding: 0.45rem 1rem;
    font-weight: 600; */
    padding-top: calc(0.375rem + 1px);
    padding-bottom: calc(0.375rem + 1px);
    margin-bottom: 0;
    font-size: 1rem;
    line-height: 1.5;
    font-weight: 600;
    text-align: right;
    padding-right: 15px;
    padding-left: 15px;
  }

  .info-div {
    /* flex: 1 1 220px;
    display: flex; */
    padding: 0 0rem 0rem 1rem;
  }

  .ant-select-selector {
    height: auto !important;
    padding: 0 !important;
    border: 0 !important;
    padding: 0.375rem 0.75rem !important;

  }

  .contact {
    /* margin: 0px 0rem 1rem 15.75rem; */
    margin: 0;
  }

  .info-div-add {
    /* flex: 1 1 100%;
  display: flex; */
    /* padding: 1rem; */
    padding: 0 8px;

    /* margin: 0px 0rem 0rem 15.75rem; */
    /* margin: 0px 0rem 0rem 6.75rem; */
  }

  /* .info-div-add .btns-add{
  display: inline-block;
    font-weight: 400;
    color: #6c757d;
    text-align: center;
    vertical-align: middle;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
} */
  .info-div-add .btns-add {
    color: #6c757d;
  }

  .info-div-add .ant-btn:hover {}

  .css-dev-only-do-not-override-diro6f.ant-btn:hover {
    text-decoration: underline !important;

  }

  .info-input {
    /* flex: 1 0 220px;
    padding: 0.375rem 0.75rem;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    color: #495057;
    background-color: #fff; */
    display: block;
    width: 100%;
    height: auto;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  }

  .input-number input {
    height: auto;
    padding: 0 !important;

  }

  .formItem-margins {
    margin-bottom: 0;
  }

  .flex-outer li button {
    margin-left: auto;
    padding: 8px 16px;
    border: none;
    background: #333;
    color: #f2f2f2;
    text-transform: uppercase;
    letter-spacing: .09em;
    border-radius: 2px;
  }

  .primary {
    color: #004085;
    background-color: #cce5ff;
    border-color: #b8daff;
    padding: 0.75rem 1.25rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    margin: 1rem 0;
    /* margin: 0px 1rem 1rem 16.75rem; */
  }

  .primary p {
    margin: 0;
  }

  /* avataaaaar */
  .avatar {
    /* Rounded border */
    border-radius: 50%;

    /* Center the content */
    align-items: center;
    display: flex;

    /* Size */
    /* height: 4rem;
    width: 4rem; */
  }

  .avatar__image {
    /* Size */
    /* height: 50%;
    width: 50%; */
  }

  /* .file-input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
} */

  /* .file-label {
  display: inline-block;
  padding: 0.5em 1em;
  color: #fff;
  border-radius: 0.25em;
  cursor: pointer;
  background-color: #007bff;
}
.file-label:hover{
  color: #fff;
    background-color: #0069d9;
    border-color: #0062cc;
} */
  .file-label-cam input[type="file"] {
    position: absolute;
    top: -1000px;
  }

  .file-label-cam {
    cursor: pointer;
    border: 1px solid #cccccc;
    border-radius: 5px;
    padding: 5px 15px;
    margin: 5px;
    background: #007bff;
    display: inline-block;
    color: white;
  }

  .file-label-cam:hover {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc;
  }

  .file-label-cam:active {
    background: #9fa1a0;
  }

  .file-label-cam:invalid+.camera-icon {
    color: #ffffff;
  }

  .file-label-cam:valid+.camera-icon {
    color: #ffffff;
  }

  .camera-button {
    /* margin: 0px 1rem 1rem 12.75rem; */
  }

  .create-btn {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
    border: 1px solid transparent;
    cursor: pointer;
    /* padding: 0.75rem 2.75rem; */
    border-radius: 0.25rem;
    /* margin: 0px 1rem 1rem 6.75rem; */
    width: 115px;
    padding: 11px 5px;
    /* margin: 0px 1rem 1rem 6.75rem; */
  }

  .cancel-btn {
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.75rem;
    border-radius: 0.25rem;
  }

  .believe-vcard {
    /* margin: 0px 1rem 1rem 6.75rem; */
  }

  .margin-contact {
    margin-bottom: 0;
  }

  .delete-btn {
    padding: 0.375rem 0.75rem;
    border: 0;
    background-color: transparent;
    font-size: 1.5rem;
  }

  @media (max-width: 576px) {
    .card {
      margin-bottom: 30px;
    }

    .remove-margin-xs {
      margin-left: 0;
    }

    .remove-margin-xs .btns-add {
      padding: 4px 0px;
    }

    .label {
      text-align: left;
      padding-top: calc(0.375rem + 1px);
      padding-bottom: calc(0.375rem + 1px);
    }

    .info-div {
      padding: 0;
    }

    .delete-btn-visibility2 {
      display: none;
    }

    .delete-btn {
      float: right;
    }

    .label label {
      padding-right: 15px;
      padding-left: 0px;
    }
  }

  @media (min-width: 577px) {
    .card {
      margin-bottom: 30px;
    }

    /* .remove-margin-xs{
  margin-left: 0;
} */
    .label {
      text-align: right;
    }

    .info-div {
      padding: 0;
    }

    .delete-btn-visibility {
      display: none;
    }

    .btns-add {
      /* margin-left: 29%; */
    }

    .remove-margin-xs .btns-add {
      padding: 4px 10px;
    }
  }

  @media (min-width: 800px) {
    .card {
      margin-bottom: 30px;
    }

    /* .remove-margin-xs{
  margin-left: 0;
} */
    .label {
      text-align: right;
    }

    .info-div {
      padding: 0;
      padding-right: 15px;
      padding-left: 15px;
    }
  }


  /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: 600px) {}

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {}

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {}

  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {}

  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {}