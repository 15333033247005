.card {
    background-color: white;
    text-align: left;
    margin-bottom: 60px;
    padding: 30px;
  }
  .card p{
    padding: 10px 0 0;
    margin: 0;
  }
  .card ol{
    margin-bottom: 0;
    margin-top: 10px;
  }
  
  .how-to-optimise{
    font-weight: 400;
    color: #007bff;
    text-decoration: none;
  }
  .how-to-optimise:hover{
    color: #0056b3;
    text-decoration: underline;
  }
  .card-popup-modal-content h1,h3{
    font-size: 16pt;
    font-weight: 500;
    line-height: 1.2;
    margin-top: 0;
    margin-bottom: 0.5rem;
  }
  .card-popup-modal-content h3{
    font-size: 13pt;
  }
  .card-popup-modal-content p{
    margin-top: 0;
    margin-bottom: 1rem;
  }
  .card-popup-modal-content img{
  box-shadow: 4px 4px 5px #ababab;
    -moz-box-shadow: 4px 4px 5px #ababab;
    border-radius: 6px;
  }

  .ant-modal-title{
    font-size: 1.5rem !important;
    font-weight: 500;
  }
  .ant-modal-header{
    border-bottom: 1px solid #dee2e6;
    padding: 1rem 1rem;
  }
  .ant-modal-body{
    padding: 1rem;
  }
  .ant-modal-footer{
    border-top: 1px solid #dee2e6;
    padding: 1rem;
  }
  .ant-card-body{
    padding: 0 !important;
  }
  .ant-modal-content{
    padding: 0 !important;
  }
  .optimise-vcard-btn{
    background-color: transparent;
    font-weight: 400;
    color: #007bff;
    text-decoration: none;
    box-shadow: none;
    font-size: 1rem;
    line-height: 1.5;
    padding: 0;
  }
  .optimise-vcard-btn:hover{
    color: #0056b3 !important;
    background-color: transparent !important;
  }
  .optimise-vcard-btn span:hover{
  text-decoration: underline !important;
  }
