.file-label {
    display: inline-block;
    padding: 0.2em 0.2em;
    border-radius: 0.25em;
    cursor: pointer;
    color: #343a40;
    border: 1px solid #343a40;
    background-color: transparent;
  }
  .file-label:hover{
    color: #fff;
    background-color: #343a40;
    border-color: #343a40;
  }
  .file-input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
  