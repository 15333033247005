.footer {
    display: flex;
    background-color: #666;
    color: white;
    padding: 1rem;
}

.feedback {
    display: grid;
    flex: 1 1 50%;
}

.feedback a {
    color: #fff;
}

.feedback p {
    margin: 0;
}

.language {
    display: grid;
    flex: 1 1 50%;
    justify-content: end;
}

.language label {
    margin-bottom: 1rem;
}

.footer-social-media {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.footer-social-media li:not(:last-child) {
    margin-right: 10px;
}

.footer-social-media li a {
    background-color: var(--text);
    color: var(--white);
    /* width: 22px; */
    /* height: 22px; */
    display: block;
    text-align: center;
    border-radius: 5px;
}

.footer-social-media li a svg:hover {
    fill: black;
}