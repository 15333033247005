.dev-content-page{
    margin: 0 auto 100px;
    max-width: 800px;
}
.dev-section{
    display: flex;
}
.dev-section-flex{
    flex-grow: 1;
}
.dev-avatar img{
    width: 100px;
      height: 100px;
      border-radius: 50%;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
}
.popup-flex{
    display: flex;
}
.popups{
  margin-bottom: 1rem;
}
.pngImage-qr{
  /* display: block;
    margin: auto;
    margin-top: 7rem;
    width: 270px; */
    text-align: center;
    padding: 5rem 0;

}
/* .file-input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  } */
  
  /* .file-label {
    display: inline-block;
    padding: 0.5em 1em;
    border-radius: 0.25em;
    cursor: pointer;
    color: #343a40;
    border: 1px solid #343a40;

  }
  .file-label:hover{
    color: #fff;
      background-color: #343a40;
  } */
  .popup-button p{
    margin: 0;
    text-align: center;
  }


  .phone-section{
    display: flex;
    margin-bottom: 1rem;
  }
  /* .left-section{
    display: flex;
    flex: 1 1 25%;
    max-width: 25%;
  } */
  .icon-section .icon-size{
    margin-right: 1rem;
    font-size: 2rem;
  }
  .clickable-links{
    text-decoration: none;
    color: inherit;
  }
  .clickable-links:hover{
    text-decoration: underline;
  }

  .edit-create-footer{
    display: grid;
    font-size: .9em;
    font-style: italic;
    margin: 2rem 0 0;
    padding: 20px;
    padding-bottom: 0px;
    text-align: center;
    border-top: 1px solid #676767;
  }
  .edit-card-link{
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
  }
  .edit-card-link:hover{
    text-decoration: underline;
  }
  .adjust{
    width: fit-content;
    margin: 0;
}
@media (max-width: 576px){
  .dev-section{
    display: grid;
  }
  .popup-button p{
    margin-bottom: 1rem;
  }
  .phone-section{
    display: grid;
  }
  .right-section{
    margin-left: calc(34px + 1rem);
  }
  }